import styled from "@emotion/styled";
import { useState } from "react";
import { formatRelative, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import uEmojiParser from 'universal-emoji-parser'

const TaskContainer = styled.div`
  animation: ${props => props.isNew ? "blink 0.66s" : "none"};
  animation-iteration-count: 3;
  border-radius: 8px;
  background: white;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 14px;
  padding: 12px 18px;
  opacity: ${props => props.clicked ? 0.5 : 1};
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-out;
  width: 100%;

  &:hover {
    opacity: ${props => props.isDone ? 1 : props.clicked ? 0.5 : 1}
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.33;
    }

    100% {
      opacity: 1;
    }
  }
`;

const TaskSquare = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 20px;
  box-shadow: ${props => props.isDone ? "0 0 0 2px rgba(0, 175, 95)" : "0 0 0 1px rgba(0, 0, 0, 0.24)"};
  cursor: pointer;
  transition: 0.16s box-shadow;

  &:hover {s
    background-color: 0 0 0 2px rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 0 2px rgba(0, 175, 95);
  }

  background-color: ${props => props.clicked ? "rgba(0, 0, 0, 0.16)" : props.isDone ? "rgba(0, 0, 0, 0.12)" : "transparent"};
`

const TaskContent = styled.div``

const TaskAssignee = styled.p`
  align-items: center;
  display: flex;
  column-gap: 6px;
  margin: 0 0 6px;
  color: rgba(0, 0, 10, 0.5);
  font-size: 15px;

  & strong {
    color: rgba(0, 0, 10, 0.6);
  }
`

const TaskAvatar = styled.div`
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  height: 20px;
  overflow: hidden;
  width: 20px;

  & img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const TaskCopied = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  opacity: 1;
  animation: fade-out 0.75s;
  animation-delay: 0.5s;
  amnimation-fill-mode: normal;

  @keyframes fade-out {
    from {
      opacity: 1
    }
    to {
      opacity: 0
    }
  }
`;

const TaskTitle = styled.div`
  align-items: center;
  color: ${props => props.hasAction ? "rgba(0, 125, 215)" : "rgba(0, 0, 10, 1)"};
  cursor: ${props => props.hasAction ? "pointer" : "default"};
  display: flex;
`;

const TaskActionIcon = styled.img`
  opacity: 0.5;
  margin-right: 8px;
`;

const TaskName = styled.p`
  align-items: center;
  margin: 0;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 500;
  text-decoration: ${props => props.clicked ? "line-through" : "none"};

  & img {
    width: 18px;
    height: 18px;
  }
`
const TaskLabel = styled.span`
  background-color: ${props => props.background};
  border-radius: 4px;
  color: white;
  font-size: 12px;
  padding: 2px 4px;
  font-weight: 600;
  margin-left: auto;
`;

const projectColors = {
  all: "rgb(24, 24, 24)",
  buscoresi: "#08B282",
  vermu: "#D62E4B",
  stak: "#985DF6"
}


function Task({ data, isNew, isDone, onClick }) {
  const [isClicked, setClicked] = useState(false)
  const [copied, setCopied] = useState(false);

  const handleClick = (id) => {
    const status = isClicked;

    setClicked(status);
    setTimeout(() => onClick(id).then(setClicked(!status)), 200);
  }

  const handleActionClick = () => {
    if (!data.action) return;
    
    window.open(data.action, "_blank");
  }

  const handleDoubleClick = () => {
    navigator.clipboard.writeText(data.taskName);
    setCopied(true);
    setTimeout(() => setCopied(false), 1250);
  }

  return <TaskContainer isNew={isNew} clicked={isClicked || isDone} isDone={isDone} onDoubleClick={handleDoubleClick}>
    {copied && <TaskCopied>✍🏻 Copied to clipboard</TaskCopied>}
    <TaskSquare clicked={isClicked} isDone={isDone} onClick={() => handleClick(data.id)} />
    <TaskContent>
      <TaskAssignee>
        <TaskAvatar>
          <img src={data.avatar} width={"20"} height={"20"} alt={data.assignee} />
        </TaskAvatar>
        <span>
          <strong>{data.assignee}</strong> ·{" "}{isDone && "completada "}
          {formatRelative(parseISO(isDone? data.updated_at : data.created_at), new Date(), {
            locale: es
          })}
        </span>
        {data.project && <TaskLabel background={projectColors[data.project] ?? "rgb(185, 185, 185)"}>#{data.project}</TaskLabel>}
      </TaskAssignee>
      <TaskTitle hasAction={!!data.action} onClick={handleActionClick}>
        {!!data.action && <TaskActionIcon src="/icon-forward.png" width="16" height="16" />}
        <TaskName clicked={isClicked || isDone} dangerouslySetInnerHTML={{ __html: uEmojiParser.parse(data.taskName) }}/>
      </TaskTitle>
    </TaskContent>
  </TaskContainer>
}

export default Task;