import { useState, useEffect } from "react";
import styled from "@emotion/styled"
import Task from "./Task";
import fetch from "isomorphic-unfetch";
import Pusher from "pusher-js";
import { parseISO, startOfDay, format, parse, getTime } from "date-fns";
import { es } from "date-fns/locale";

const CRITICAL_WEIGHT = 15;
const API_URL = "https://todo.vermu.io/api/tasks";
const DELETE_URL = "https://todo.vermu.io/api/delete";

const TaskContainer = styled.div`
  align-items: center;
  background-color: ${props => props.isBacklogOn 
    ? "rgba(0, 0, 0, 0.06)" 
    : props.newTaskAlert 
      ? "rgba(0, 0, 255, 0.12)" 
      : `rgba(214, 46, 75, ${props.weight})`
    };
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 20px 12px;
  box-sizing: border-box;
`;

const TaskStack = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  height: 100%;
  row-gap: 10px;
  width: 100%;
  z-index: 2;
  position: relative;
`;

const TaskNumber = styled.div`
  -webkit-text-stroke: 3px ${props => props.isBacklogOn ? "rgba(0, 0, 0, 0.02)": props.newTaskAlert ?
      "rgba(0, 0, 255, 0.66)" :
      props.weight > 0 ?
        `rgba(214, 46, 75, ${props.weight})` :
        "rgba(0, 150, 70, 0.16)"
      };
  color: transparent;
  position: fixed;
  top: 0;
  line-height: 100vh;
  font-size: 100vh;
  letter-spacing: -4vh;
  font-weight: 600;
`;

const BacklogDate = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: rgba(9, 9, 25, 1);
  margin-bottom: 8px;
`;

const Button = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  color: ${props => props.background ?? "rgba(24, 24, 24, 0.88)"};
  text-transform: uppercase;
  border-radius: 4px;

  &:hover {
    opacity: 0.75
  }
`;

const ProjectButtonGroup = styled.div`
  background-color: white;
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  margin-top: 8px;
  padding: 4px;
  position: relative;
  z-index: 4;
  width: 100%;
`;

const ProjectButton = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  color: ${props => !props.isActive ? "rgba(24, 24, 24)" : "white"};
  background-color: ${props => !props.isActive ? "white" : props.background};
  font-weight: 600;
  border-radius: 16px;
  font-size: 14px;
  padding: 6px 12px;

  &:hover {
    background-color: ${props => !props.isActive ? "rgba(230, 230, 230)" : props.background};
  }

  & small {
    opacity: .5;
  }
`;

const projectColors = {
  all: "rgb(24, 24, 24)",
  buscoresi: "#08B282",
  vermu: "#D62E4B",
  stak: "#985DF6"
}

const pusher = new Pusher('065491edf3cf12e5c9bc', {
  cluster: 'eu'
});

const channel = pusher.subscribe('my-channel');

function notifyMe(data) {
  const audio = new Audio('/noti.wav');
  audio.play();

  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  else if (Notification.permission === "granted") {
    new Notification(`Hey, ${data.assignee}`, { body: data.taskName, icon: "/favicon.ico"  });
  }

  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        new Notification(`Hey, ${data.assignee}`, { body: data.taskName, icon: "/favicon.ico" });
      }
    });
  }
}

function App() {
  const [tasks, setTasks] = useState()
  const [projects, setProjects] = useState({});
  const [currentProject, setCurrentProject] = useState("all");

  const [showBacklog, setShowBacklog] = useState(false);
  const [backlog, setBacklog] = useState()

  const [newestTask, setNewestTask] = useState(-1)
  const [newTaskAlert, setNewTaskAlert] = useState(false)

  const weight = tasks?.filter(t => t.status !== 1).length || 0;

  const getBacklogGroups = (tasks) => {
    let groups = {};

    for (let task of [...tasks]) {
      const updateDay = startOfDay(parseISO(task.updated_at)).toISOString();

      if (groups[updateDay] === undefined) groups[updateDay] = [task];
      else {
        groups[updateDay].push(task)
      }
    }
    
    let groupList = Object.entries(groups)
      .map(([k, v]) => ({ date: k, tasks: v }))
      .sort((a, b) => {
        const offset = getTime(parseISO(a.date)) - getTime(parseISO(b.date))
        return offset
      })

    return [...groupList]
  }

  const getProjects = (tasks) => {
    const _proj = { };

    for (let task of tasks.filter(t => t.status !== 1)) {
      if (task.project !== null) {
        if (!_proj[task.project]) _proj[task.project] = 1;
        else _proj[task.project] += 1;
      }
    }

    return { ..._proj }
  }

  async function refreshTasks() {
    const res = await fetch(API_URL);
    const data = await res.json();

    setTasks(data)
    setProjects(getProjects(data));
    setBacklog(getBacklogGroups(data.filter(t => t.status === 1)))
  }

  const handleTaskComplete = async id => {
    await fetch(DELETE_URL + "?id=" + id)
    await refreshTasks();

    return true
  }

  useEffect(() => {
    refreshTasks();

    channel.bind(`App\\Events\\task`, data => {
      refreshTasks()
      notifyMe(data)
    
      setNewestTask(data.id)
      setNewTaskAlert(true);

      setTimeout(() => {
        setNewTaskAlert(false)
      }, 2000)
    });

    channel.bind(`App\\Events\\delete`, data => {
      setTasks(t => [...t.filter(_t => _t.id !== data.id)])
    });
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 1e6, behavior: "smooth" })

    if (tasks && tasks.length > 0)
      document.title = `stak (${tasks.filter(t => t.status !== 1).length})`;
  }, [tasks])

  useEffect(() => {
    if (showBacklog === true) {
      window.scrollTo({ top: 1e6 })
    }
  }, [showBacklog])

  useEffect(() => {
    if (currentProject) {
      window.scrollTo({ top: 1e6, behavior: "smooth" })
    }
  }, [currentProject])

  console.log(projects)

  return (
    <TaskContainer isBacklogOn={showBacklog} newTaskAlert={newTaskAlert} weight={Math.min(weight/(CRITICAL_WEIGHT*3), 1)}>
      <TaskStack direction={showBacklog ? "column" : "column-reverse"}>
        {!showBacklog ? <>
          <Button onClick={() => setShowBacklog(true)}>Show backlog</Button>
          {projects && Object.keys(projects).length > 0 && <ProjectButtonGroup>
              <ProjectButton background={projectColors["all"]} isActive={currentProject === "all"} onClick={() => setCurrentProject("all")}>
                All
              </ProjectButton>
              {Object.entries(projects).map(([k, v]) => <ProjectButton key={k} background={projectColors[k] ?? "rgb(185, 185, 185)"} onClick={() => setCurrentProject(k)} project={k} isActive={k === currentProject}>{k} <small>({v})</small></ProjectButton>)}
            </ProjectButtonGroup>
          }
          {(tasks || [])
            .filter(t => t.status !== 1 && (currentProject === "all" || t.project === currentProject))
            .map(task => (
              <Task
                isNew={newTaskAlert && newestTask === task.id}
                key={task.created_at}
                data={task}
                onClick={handleTaskComplete}
              />
              )
            )}
          </> : <>
            {backlog && backlog.map(({ date, tasks = [] }) => (
              <>
              <BacklogDate>{format(parseISO(date), "PPP", { locale: es })}</BacklogDate>
              {tasks.map(task => (
                <Task
                  isNew={newTaskAlert && newestTask === task.id}
                  isDone={true}
                  key={task.created_at}
                  data={task}
                  onClick={handleTaskComplete}
                />
              ))}
              </>
            ))}
            <Button background="#D62E4B" onClick={() => setShowBacklog(false)}>Hide backlog</Button>
          </>}
      </TaskStack>
      <TaskNumber
        isBacklogOn={showBacklog}
        newTaskAlert={newTaskAlert}
        weight={Math.min(weight/(CRITICAL_WEIGHT*3), 1)}
      >
        {showBacklog ? "?" : newTaskAlert ? "!!" : weight > 0 ? weight : ":)"}
      </TaskNumber>
    </TaskContainer>
  );
}

export default App;
